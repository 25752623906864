<template>
  <standard-page title="Process Definition Registries" :definition="$DEFINITIONS.provenance.registry">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>Registries</span>
    </template>

    <template v-slot:buttons>
      <button @click="newRegistry()" class="d-none d-xl-block float-right btn btn-orange rounded font-weight-bold" title="Add Registry">
        <i class="fas fa-plus-square mg-r-10" />Add Registry
      </button>
    <button @click="newRegistry()" class="d-xl-none w-35 float-right btn btn-icon btn-orange rounded" title="Add Registry">
        <i class="typcn typcn-document-add" />
    </button>
    </template>

    <template v-slot:content>
      <data-table class="table dataTable no-footer" :api="api" :columns="columns" :rename="updateRegistry" :row-click="view" :server-side="true" @ready="onReady" list-key="registries" table-id="registry_table" total-key="total">
      </data-table>
      <delete-modal :service="'provenance'" :context="'Registry'" :table="table" :url="`provenance/registries/${registryIdToDelete}`" v-if="registryIdToDelete"></delete-modal>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Registries",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      api: `${this.$provenance.defaults.baseURL}/provenance/registries`,
      columns: [
        { type: 'name' },
        { type: 'created' },
        { type: 'extrinsic' },
        {
          type: 'action',
          defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                            ${this.$StandardButtons.renameButton('Rename Registry')}
                            ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Registry')}
                           </div>`
        }
      ],
      table: null,
      registry: {
        name: null,
        registry: null
      },
      registryIdToDelete: null,
      openDeleteModal: false
    };
  },
  mounted() {
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#registry_table tbody').on('click', '.delete', function () {
          let registry = table.row($(this).parents('tr')).data();
          self.setRegistryIdToDelete(registry.registry);
          table.ajax.reload();
        });
      });
    },
    setRegistryIdToDelete(registryid) {
      this.registryIdToDelete = registryid;
    },
    view(registry) {
      this.$router.push({ name: 'processdefinitions', params: { registryid: registry.registry } });
    },
    newRegistry() {
      this.$router.push({ name: 'new-provenances-registries' });
    },
    async updateRegistry(registry, name) {
      if (name.trim().length > 0) {
        try {
          await this.$provenance.patch(`provenance/registries/${registry.registry}`, { name });
          this.$toastr.s("Registry Successfully updated!", 'Success');
        } catch (e) {
          this.$toastr.e("Registry updating failed", 'Error');
        }
      } else {
        this.$toastr.e("Please provide a name first!", 'Failed');
      }
    }
  }
}
</script>

<style scoped>
</style>

